import {PropsWithChildren, createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useLazyQuery, useQuery} from '@apollo/client';
import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {CONTACT, FARMS, GAUGES, GET_WHITELIST_TOKENS, LIQUIDITY_POOLS, MASTER_CHEF_CONTRACTS} from 'src/graphql/query';
import {USER_FEE} from 'src/graphql/query/userFee';
import {useGetBalance, useNativeToken} from 'src/hooks';
import {userServicesClient} from 'src/providers/GraphqlProvider';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {
  FarmType,
  Fee,
  IFarm,
  IFarmData,
  IGauge,
  IGaugeData,
  IGrizzlyFiFarm,
  IWhitelistToken,
  IWhitelistTokenItem,
  IWhitelisttokenData,
} from 'src/types';
import {IFarmingPool, IMasterChefContractData} from 'src/types/masterchefcontract';
import {ILiquidityPool, ILiquidityPoolData} from 'src/types/pool';
import {setMasterChefAbis} from 'src/utils/storage-util';
import {calculatePrice, formatBigNumber} from 'src/utils/token-util';
import {randomColor} from 'src/utils/utils';

interface AppContextValue {
  tokens?: IWhitelistToken[];
  allTokens?: IWhitelistTokenItem[];
  liquidityPools?: ILiquidityPool[];
  farmingPools?: IFarmingPool[];
  farms?: IFarm[];
  gauges?: IGauge[];
  grizzlyFarms?: IGrizzlyFiFarm[];
  farmLoading: boolean;
  lastHoldingsLoad?: boolean;
  userFee?: number;
  refreshTokens: (shouldUpdateBalance: boolean) => void;
  refreshFarmsAndLPs: () => void;
}

export const AppContext = createContext<AppContextValue>({
  tokens: [],
  allTokens: [],
  farmingPools: [],
  farms: [],
  gauges: [],
  grizzlyFarms: [],
  farmLoading: false,
  userFee: 0,
  refreshTokens: () => null,
  refreshFarmsAndLPs: () => null,
});

// DisplayName is used by React context Devtool to debug the state
AppContext.displayName = 'AppContext';

export const AppContextProvider = (props: PropsWithChildren) => {
  const {chainId, account} = useWeb3React();
  const appDispatch = useAppDispatch();
  const {customTokens} = useAppSelector((state) => state.user);
  const {nativeToken: baseNativeToken, getNativeTokenBalance} = useNativeToken();
  const {getTokenBalance, getFarmPoolBalances, getLiquidityPoolBalances, getFarmBalances, getGaugeBalances} =
    useGetBalance();
  const [whiteListTokens, setWhitelistTokens] = useState<IWhitelistToken[]>([]);
  const [liquidityPools, setLiquidityPools] = useState<ILiquidityPool[]>([]);
  const [farmingPools, setFarmingPools] = useState<IFarmingPool[]>([]);
  const [farms, setFarms] = useState<IFarm[]>([]);
  const [gauges, setGauges] = useState<IGauge[]>([]);
  const [grizzlyFarms] = useState<IGrizzlyFiFarm[]>([]);
  const [farmLoading, setFarmLoading] = useState(false);
  const [lastHoldingsLoad, setlastHoldingsLoad] = useState(false);
  const [userFee, setUserFee] = useState(0);

  const isFarmingPoolBusy = useRef(false);
  const isLiquidityPoolBusy = useRef(false);
  const isFarmBusy = useRef(false);
  const isGaugeBusy = useRef(false);
  const {data: tokenData, loading: getTokenLoading} = useQuery<IWhitelisttokenData>(GET_WHITELIST_TOKENS, {
    variables: {skip: 0, limit: 300},
    onCompleted: () => {
      setFarmLoading(false); // Set loading to false after data is loaded
    },
    onError: () => {
      setFarmLoading(false); // Set loading to false in case of an error
    },
  });
  useEffect(() => {
    if (getTokenLoading) {
      setFarmLoading(true);
      setlastHoldingsLoad(true);
    }
  }, [getTokenLoading]);
  const tokenList = useMemo(() => {
    const list: IWhitelistToken[] = [];
    if (tokenData?.tokens) {
      for (const tokenItem of tokenData.tokens) {
        let chainItem = null;
        for (const chainSpecific of tokenItem.chainSpecifics) {
          if (chainSpecific.address.chainId === chainId) {
            chainItem = chainSpecific;
          }
        }
        if (chainItem !== null) {
          const item = {
            globalName: tokenItem.globalName,
            name: chainItem.name,
            symbol: chainItem.symbol,
            decimals: chainItem.decimals,
            priceUSD: chainItem.priceUSD,
            interfaceDecimals: tokenItem.interfaceDecimals,
            priceDecimals: tokenItem.priceDecimals,
            marketCap: tokenItem.marketCap,
            address: chainItem.address.hash,
            chainSpecifics: tokenItem.chainSpecifics,
            balance: BigNumber.from(0),
            chainId: chainItem.address.chainId,
            iconUrl: tokenItem.iconUrl,
          };
          list.push(item);
        }
      }
    }
    const _customTokens = customTokens?.filter((customToken) => customToken.chainId === chainId) || [];
    return [...list, ..._customTokens];
  }, [tokenData, chainId, customTokens]);

  const nativeToken: IWhitelistToken | undefined = useMemo(() => {
    let wrappedNativeToken;
    if (chainId === 1) {
      wrappedNativeToken = tokenList?.find((item) => item.symbol === 'WETH');
    } else if (chainId === 25) {
      wrappedNativeToken = tokenList?.find((item) => item.symbol === 'WCRO');
    } else if (chainId === 56) {
      wrappedNativeToken = tokenList?.find((item) => item.symbol === 'WBNB');
    } else if (chainId === 137) {
      wrappedNativeToken = tokenList?.find((item) => item.symbol === 'WMATIC');
    }
    if (baseNativeToken && wrappedNativeToken) {
      return {
        ...baseNativeToken,
        priceUSD: wrappedNativeToken.priceUSD,
        interfaceDecimals: wrappedNativeToken.interfaceDecimals,
        priceDecimals: wrappedNativeToken?.priceDecimals,
      };
    }
  }, [baseNativeToken, chainId, tokenList]);

  const refreshTokens = useCallback(
    async (shouldUpdateBalance: boolean) => {
      let _whiteListTokens: IWhitelistToken[] = [];
      setFarmLoading(true);
      if (nativeToken) {
        const newNativeToken = {...nativeToken};
        if (shouldUpdateBalance) {
          const nativeBalance = await getNativeTokenBalance();
          newNativeToken.balance = nativeBalance;
        }
        _whiteListTokens = [newNativeToken];
      }
      if (tokenList.length > 0) {
        let updatedTokenList = tokenList.filter(
          (value, index, self) => index === self.findIndex((t) => t.address === value.address),
        );
        if (shouldUpdateBalance) {
          try {
            const balances = await getTokenBalance(updatedTokenList);
            if (balances) {
              updatedTokenList = balances.map(
                (item, index) =>
                  ({
                    ...updatedTokenList[index],
                    balance: item as BigNumber,
                  } as IWhitelistToken),
              );
            }
          } catch (e) {
            console.log(e);
          }
        }
        _whiteListTokens = _whiteListTokens.concat(updatedTokenList);
      }
      if (nativeToken || tokenList.length > 0) {
        setWhitelistTokens(_whiteListTokens);
      }
      setFarmLoading(false);
    },
    [getNativeTokenBalance, getTokenBalance, nativeToken, tokenList],
  );

  const updateMasterchefBalances = async (masterChefContractList: IFarmingPool[], masterChefAbis: string) => {
    if (!isFarmingPoolBusy.current && masterChefContractList) {
      isFarmingPoolBusy.current = true;
      try {
        let updatedPoolList: IFarmingPool[] = [];
        if (account && masterChefContractList.length > 0) {
          const [tokenBalances, tokenRewards] = await getFarmPoolBalances(masterChefContractList, masterChefAbis);
          updatedPoolList = tokenBalances.map((item, index) => ({
            ...masterChefContractList[index],
            balance: item ? item[0] : BigNumber.from(0),
          }));
          updatedPoolList = tokenRewards.map((item, index) => ({
            ...updatedPoolList[index],
            pendingReward: item
              ? formatBigNumber(item, masterChefContractList[index]?.rewardTokenChainSpecifics?.decimals)
              : 0,
          }));
        }
        setFarmingPools(account ? updatedPoolList : masterChefContractList);
      } catch (e) {
        console.log(e);
      }
      isFarmingPoolBusy.current = false;
    }
  };

  const updateLiquidityPoolBalances = async (liquidityPoolList: ILiquidityPool[]) => {
    if (!isLiquidityPoolBusy.current && liquidityPoolList) {
      isLiquidityPoolBusy.current = true;
      try {
        let updatedPoolList: ILiquidityPool[] = [];
        if (account && liquidityPoolList.length > 0) {
          const tokenBalances = await getLiquidityPoolBalances(liquidityPoolList);
          updatedPoolList = tokenBalances.map((item, index) => ({
            ...liquidityPoolList[index],
            balance: item ?? BigNumber.from(0),
          }));
          updatedPoolList = updatedPoolList.filter((item) => formatBigNumber(item.balance, item.decimals) > 0.000001);
        }
        setLiquidityPools(account ? updatedPoolList : liquidityPoolList);
      } catch (e) {
        console.log(e);
      }
      isLiquidityPoolBusy.current = false;
    }
  };

  const updateFarmBalances = async (farmList: IFarm[]) => {
    if (!isFarmBusy.current) {
      isFarmBusy.current = true;
      try {
        let updatedFarmList: IFarm[] = [];
        if (farmList.length > 0) {
          if (account) {
            const [tokenBalances, tokenRewards] = await getFarmBalances(farmList);
            updatedFarmList = tokenBalances.map((item, index) => ({
              ...farmList[index],
              type: farmList[index].liquidityPool ? FarmType.FARM_LP : FarmType.FARM_SINGLE,
              balance: item ? item[0] : BigNumber.from(0),
            }));
            updatedFarmList = tokenRewards.map((item, index) => ({
              ...updatedFarmList[index],
              pendingReward: item ? formatBigNumber(item, farmList[index].rewardTokenChainSpecifics?.decimals) : 0,
            }));
          } else {
            updatedFarmList = farmList.map((item) => ({
              ...item,
              type: item.liquidityPool ? FarmType.FARM_LP : FarmType.FARM_SINGLE,
            }));
          }
        }
        setFarms(updatedFarmList);
      } catch (e) {
        console.log(e);
      }
      isFarmBusy.current = false;
    }
  };

  const updateGaugeBalances = async (gaugeList: IGauge[]) => {
    if (!isGaugeBusy.current) {
      isGaugeBusy.current = true;
      try {
        let updatedGaugeList: IGauge[] = [];
        if (account && gaugeList.length > 0) {
          const [tokenBalances, tokenRewards] = await getGaugeBalances(gaugeList);
          updatedGaugeList = tokenBalances.map((item, index) => ({
            ...gaugeList[index],
            balance: item ? item : BigNumber.from(0),
          }));
          updatedGaugeList = tokenRewards.map((item, index) => ({
            ...updatedGaugeList[index],
            pendingReward: item ? formatBigNumber(item, gaugeList[index].rewardTokenChainSpecifics?.decimals) : 0,
          }));
        }
        setGauges(account ? updatedGaugeList : gaugeList);
      } catch (e) {
        console.log(e);
      }
      isFarmBusy.current = false;
    }
  };

  const [refreshMasterChefContracts] = useLazyQuery<IMasterChefContractData>(MASTER_CHEF_CONTRACTS, {
    onCompleted: async (data) => {
      const _masterChefContracts = data.masterChefContracts;
      const _filteredData = _masterChefContracts.filter((item) => {
        return item.address.chainId === chainId && (item.version !== 1 || item.platform.name !== 'MM Finance');
      });
      const _farmingPools: IFarmingPool[] = [];
      let _abis = {};
      _filteredData.forEach((_item) => {
        const abi = JSON.parse(_item.config.masterChefAbi || '');
        _abis = {
          ..._abis,
          [`${_item.platform.name}${_item.version ?? ''}`]: abi,
        };
        _item.whitelistedFarmingPools.forEach((item2) => {
          const _farmPool = {
            type: FarmType.FARM_MASTERCHEF,
            platform: _item.platform,
            poolId: item2.poolId,
            rewardTokenChainSpecifics: _item.rewardTokenChainSpecifics,
            pendingRewardFn: _item.config.pendingRewardFn,
            harvestFn: _item.config.harvestFn,
            depositFn: _item.config.depositFn,
            withdrawFn: _item.config.withdrawFn,
            masterChefAddress: _item.address,
            liquidityPool: item2.liquidityPool,
            apy: item2.apy,
            baseAPR: item2.baseAPR,
            tvlNumber: calculatePrice(item2?.tvl, 0),
            hotness: item2.hotness,
            version: _item.version,
          };
          _farmingPools.push(_farmPool);
        });
      });
      await setMasterChefAbis(JSON.stringify(_abis));
      updateMasterchefBalances(_farmingPools, JSON.stringify(_abis));
    },
  });

  const [refreshLiquidityPool] = useLazyQuery<ILiquidityPoolData>(LIQUIDITY_POOLS, {
    onCompleted: async (data) => {
      const _liquidityPools = data.liquidityPools;
      const _filteredData = _liquidityPools.filter((item) => {
        return item.address?.chainId === chainId;
      });
      updateLiquidityPoolBalances(_filteredData);
    },
    onError(error) {
      console.error(error);
    },
  });

  const [refreshFarms] = useLazyQuery<IFarmData>(FARMS, {
    onCompleted: async (data) => {
      const farms = data.farms;
      const _filteredData = farms.filter((item) => {
        return item.contractAddress?.chainId === chainId;
      });
      const _filteredFarms: IFarm[] = [];
      _filteredData.forEach((item) => {
        const _farmPool = {
          ...item,
          type: item.liquidityPool ? FarmType.FARM_LP : FarmType.FARM_SINGLE,
          tvlNumber: calculatePrice(item?.tvl, 0),
        };
        _filteredFarms.push(_farmPool);
      });
      setFarms(_filteredFarms);
      updateFarmBalances(_filteredFarms);
    },
  });

  const [refreshGauges] = useLazyQuery<IGaugeData>(GAUGES, {
    variables: {chainId},
    onCompleted: async (data) => {
      const _gauges = data.whitelistedGauges;
      const updatedGauges = _gauges.map((item) => ({
        ...item,
        tvlNumber: calculatePrice(item.liquidityPool?.lpValueUSD),
        pendingRewardFn: item.voterContract?.config.rewardsFn,
        harvestFn: item.voterContract?.config.harvestFn,
        depositFn: item.voterContract?.config.depositFn,
        withdrawFn: item.voterContract?.config.withdrawFn,
        gaugeAbi: item.voterContract?.config.gaugeAbi,
        balanceFn: item.voterContract?.config.balanceFn,
        type: FarmType.FARM_GAUGE,
      }));
      setGauges(updatedGauges);
      updateGaugeBalances(updatedGauges);
    },
  });

  const [refreshUserFee] = useLazyQuery<Fee>(USER_FEE, {
    client: userServicesClient,
    variables: {accountAddress: account},
    onCompleted: (response) => {
      setUserFee(response?.getUserFee);
    },
    onError: (e: unknown) => {
      console.log({e});
    },
  });

  // const [refreshGrizlyFarms] = useLazyQuery<IGrizzlyFiFarmData>(GRIZLYFI_FARM, {
  //   onCompleted: async (data) => {
  //     const grizlyFarms = data.grizlyfiFarms;
  //     const _filteredData = grizlyFarms.filter((item) => {
  //       return item.contractAddress?.chainId === chainId;
  //     });
  //     const _filteredGrizlyFarm: IGrizzlyFiFarm[] = [];
  //     _filteredData.forEach((item) => {
  //       const _farmPool = {
  //         ...item,
  //         type: FarmType.FARM_GRIZLYFI,
  //         liquidityPool: item,
  //         tvlNumber: calculatePrice(item?.tvl, 0),
  //       };
  //       _filteredGrizlyFarm.push(_farmPool);
  //     });
  //     setGrizzlyFarms(_filteredGrizlyFarm);
  //     updateGrizzlyBalances(_filteredGrizlyFarm);
  //   },
  // });

  const [fetchContactData] = useLazyQuery(CONTACT, {
    variables: {accountAddress: account},
    onCompleted: async (data) => {
      const contactList = data.getUserPreference;
      if (contactList && contactList.contacts.length > 0) {
        const {contacts} = contactList;
        const contactsWithColor = contacts.map((item: {hash: string; name: string}) => ({
          name: item.name,
          address: item.hash,
          color: randomColor(),
        }));
        const payload = {addressBook: contactsWithColor};
        appDispatch(userSlice.actions.updateContactBook(payload));
      }
    },
    client: userServicesClient,
  });

  const refreshFarmsAndLPs = useCallback(async () => {
    //const platformSlug = platformSettings?.platform?.slug || 'all';
    const platformSlug = 'all';
    const _masterChefVariables = {
      chainId,
      ...(platformSlug !== 'all' && {platformSlug: platformSlug}),
    };
    const _liquidityVariables = {
      skip: 0,
      limit: 3000,
      chainId,
      ...(platformSlug !== 'all' && {platformSlug: platformSlug}),
    };
    setFarmLoading(true);
    // TODO: should only be triggerd when chainId is not undefined
    await refreshUserFee();
    await refreshMasterChefContracts({variables: _masterChefVariables});
    await refreshLiquidityPool({variables: _liquidityVariables});
    await refreshFarms({variables: _masterChefVariables});
    await refreshGauges({variables: _masterChefVariables});
    //await refreshGrizlyFarms();
    setFarmLoading(false);
    setlastHoldingsLoad(false);
  }, [chainId, refreshMasterChefContracts, refreshLiquidityPool, refreshFarms, refreshGauges, refreshUserFee]);

  useEffect(() => {
    if (account) {
      fetchContactData();
    }
    refreshTokens(false);
    refreshTokens(true);
    refreshFarmsAndLPs();
  }, [account, refreshTokens, refreshFarmsAndLPs, fetchContactData]);

  const values = useMemo(
    () => ({
      tokens: whiteListTokens,
      allTokens: tokenData?.tokens,
      liquidityPools,
      farmingPools,
      farms,
      gauges,
      grizzlyFarms,
      farmLoading,
      lastHoldingsLoad,
      userFee,
      refreshTokens,
      refreshFarmsAndLPs,
    }),
    [
      whiteListTokens,
      tokenData?.tokens,
      liquidityPools,
      farmingPools,
      farms,
      gauges,
      grizzlyFarms,
      farmLoading,
      lastHoldingsLoad,
      userFee,
      refreshTokens,
      refreshFarmsAndLPs,
    ],
  );

  return <AppContext.Provider value={values}>{props.children}</AppContext.Provider>;
};
