import {useCallback, useEffect, useState} from 'react';

import {ChatTurn} from '@vectara/react-chatbot/lib/types';
import {useChat} from '@vectara/react-chatbot/lib/useChat';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {Conversation} from 'src/types/ChatBot/chatBotProps';

export const useChatbot = () => {
  const storedConversations = useAppSelector((state) => state.user.conversations || []);
  const appDispatch = useAppDispatch();

  const {sendMessage, messageHistory, isLoading, isStreamingResponse, hasError} = useChat({
    customerId: '2672775331',
    corpusIds: ['3'],
    apiKey: process.env.REACT_APP_VECTARA_API,
    enableStreaming: true,
    language: 'eng',
  });

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [currentMessages, setCurrentMessages] = useState<ChatTurn[]>([]);
  const [currentConversationIndex, setCurrentConversationIndex] = useState<number | null>(null);

  useEffect(() => {
    if (storedConversations.length > 0) {
      setConversations(storedConversations);
    }
  }, [storedConversations]);

  const sendQuery = async (query: string) => {
    // Immediately add the question to the current messages
    const newQuestion = {question: query, answer: ''} as ChatTurn;
    setCurrentMessages((prevMessages) => [...prevMessages, newQuestion]);

    await sendMessage({query});
  };

  const handleNewMessages = useCallback(
    (newMessages: ChatTurn[]) => {
      if (newMessages.length < 1) return;
      setConversations((prevConversations) => {
        let updatedConversations = [...prevConversations];

        if (currentConversationIndex === null || updatedConversations.length === 0) {
          // Handle case when there is no current conversation
          const newConversation: Conversation = {
            messages: newMessages,
            timestamp: new Date().toISOString(),
          };
          updatedConversations = [newConversation, ...updatedConversations];
          setCurrentConversationIndex(updatedConversations.length - 1);
        } else {
          // Handle case when there is a current conversation
          const existingMessages = updatedConversations[currentConversationIndex].messages;
          const newUniqueMessages = newMessages.filter(
            (msg) => !existingMessages.some((em) => em.question === msg.question && em.answer === msg.answer),
          );

          const updatedMessages = [...newUniqueMessages, ...existingMessages];
          updatedConversations[currentConversationIndex] = {
            ...updatedConversations[currentConversationIndex],
            messages: updatedMessages,
          };
        }

        try {
          appDispatch(userSlice.actions.updateConversations({conversations: updatedConversations}));
        } catch (error) {
          console.error('Adding conversation to reducer failed:', error);
        }
        return updatedConversations;
      });
    },
    [appDispatch, currentConversationIndex],
  );

  useEffect(() => {
    if (messageHistory && messageHistory.length > 0) {
      const newMessage = messageHistory
        .map((msg) => ({
          question: msg.question,
          answer: msg.answer.replace(/\[\d+\]/g, '').trim(),
        }))
        .pop();
      currentMessages[currentMessages.length - 1].answer = newMessage?.answer;
      setCurrentMessages([...currentMessages]);
      handleNewMessages(currentMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageHistory]);

  const loadConversation = (index: number) => {
    setCurrentMessages(conversations[index].messages);
    setCurrentConversationIndex(index);
  };

  const clearCurrentMessages = () => {
    setCurrentMessages([]);
  };

  const removePreviousConversation = (index: number) => {
    const updatedConversations = conversations.filter((_item, idx) => idx !== index);
    setConversations(updatedConversations);
    appDispatch(userSlice.actions.updateConversations({conversations: updatedConversations}));
  };

  return {
    sendQuery,
    currentMessages,
    isLoading,
    isStreamingResponse,
    hasError,
    loadConversation,
    conversations,
    clearCurrentMessages,
    removePreviousConversation,
  };
};
