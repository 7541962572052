import BinanceIcon from 'src/assets/images/tokens/BNB.png';
import CronosIcon from 'src/assets/images/tokens/CRONOS.png';
import EthIcon from 'src/assets/images/tokens/ETH.png';
import MaticIcon from 'src/assets/images/tokens/MATIC.png';
import {NetworkType} from 'src/types';

export type ChainConfig = {[chainId: number]: NetworkType};

export const NETWORKS: ChainConfig = {
  56: {
    chainId: 56,
    hexChainId: '0x38',
    name: 'BNB Smart Chain',
    displayName: 'Binance Smart Chain',
    rpcUrl: ['https://bsc-dataseed.binance.org/', 'https://bsc-dataseed3.binance.org'],
    symbol: 'BNB',
    decimals: 18,
    blockExplorerUrls: 'https://bscscan.com/',
    icon: BinanceIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/bsc/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0xD6b8aA87b61ac037374F0085936Ad13268ef10d5',
    liquidusAutoLPFarmOutAddress: '0x3505aFC3a5e9D0650712f58A2D433547dc6c1825',
    shortName: 'bsc',
    explorerApiUrl: 'https://api.bscscan.com',
  },
  1: {
    chainId: 1,
    hexChainId: '0x1',
    name: 'Ethereum',
    displayName: 'Ethereum Mainnet',
    rpcUrl: ['https://mainnet.infura.io/v3/c271809d01134d70875d1a9761c855b2'],
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: 'https://etherscan.io',
    icon: EthIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/ethereum/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0x3e09Ca6da51Bc2Af8868c13e32f00Ecdcb726517',
    liquidusAutoLPFarmOutAddress: '0xA565E73431c1AFCed670DC6Dd13a4956b62f30aB',
    shortName: 'eth',
    explorerApiUrl: 'https://etherscan.io',
  },
  25: {
    chainId: 25,
    hexChainId: '0x19',
    name: 'Cronos',
    displayName: 'Cronos',
    rpcUrl: ['https://evm-cronos.crypto.org', 'https://evm.cronos.org'],
    symbol: 'CRO',
    decimals: 18,
    blockExplorerUrls: 'https://cronoscan.com/',
    icon: CronosIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/cronos/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0xFf2c6a6540325F69cADaC6380D35dAC971A71F02',
    liquidusAutoLPFarmOutAddress: '0xEa4E7c98AB551097E23389B7BF74341C3D45c8Bf',
    shortName: 'cronos',
  },
  137: {
    chainId: 137,
    hexChainId: '0x89',
    name: 'Polygon',
    displayName: 'Polygon Mainnet',
    rpcUrl: ['https://polygon-rpc.com/'],
    symbol: 'POL',
    decimals: 18,
    blockExplorerUrls: 'https://polygonscan.com/',
    icon: MaticIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/polygon/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0x3e09Ca6da51Bc2Af8868c13e32f00Ecdcb726517',
    liquidusAutoLPFarmOutAddress: '0xA565E73431c1AFCed670DC6Dd13a4956b62f30aB',
    shortName: 'polygon',
    explorerApiUrl: 'https://polygonscan.com',
  },
};

export const SUPPORTED_NETWORKS: ChainConfig = {
  56: {
    chainId: 56,
    hexChainId: '0x38',
    name: 'BNB Smart Chain',
    displayName: 'Binance Smart Chain',
    rpcUrl: ['https://bsc-dataseed.binance.org/', 'https://bsc-dataseed3.binance.org'],
    symbol: 'BNB',
    decimals: 18,
    blockExplorerUrls: 'https://bscscan.com/',
    icon: BinanceIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/bsc/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0xD6b8aA87b61ac037374F0085936Ad13268ef10d5',
    liquidusAutoLPFarmOutAddress: '0x3505aFC3a5e9D0650712f58A2D433547dc6c1825',
    shortName: 'bsc',
    explorerApiUrl: 'https://api.bscscan.com',
  },
  1: {
    chainId: 1,
    hexChainId: '0x1',
    name: 'Ethereum',
    displayName: 'Ethereum Mainnet',
    rpcUrl: ['https://mainnet.infura.io/v3/c271809d01134d70875d1a9761c855b2'],
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: 'https://etherscan.io',
    icon: EthIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/ethereum/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0x3e09Ca6da51Bc2Af8868c13e32f00Ecdcb726517',
    liquidusAutoLPFarmOutAddress: '0xA565E73431c1AFCed670DC6Dd13a4956b62f30aB',
    shortName: 'eth',
    explorerApiUrl: 'https://etherscan.io',
  },
  137: {
    chainId: 137,
    hexChainId: '0x89',
    name: 'Polygon',
    displayName: 'Polygon Mainnet',
    rpcUrl: ['https://polygon-rpc.com/'],
    symbol: 'POL',
    decimals: 18,
    blockExplorerUrls: 'https://polygonscan.com/',
    icon: MaticIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/polygon/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0x3e09Ca6da51Bc2Af8868c13e32f00Ecdcb726517',
    liquidusAutoLPFarmOutAddress: '0xA565E73431c1AFCed670DC6Dd13a4956b62f30aB',
    shortName: 'polygon',
    explorerApiUrl: 'https://polygonscan.com',
  },
};

export const LIQUIDITY_NETWORKS: ChainConfig = {
  56: {
    chainId: 56,
    hexChainId: '0x38',
    name: 'BNB Smart Chain',
    displayName: 'Binance Smart Chain',
    rpcUrl: ['https://bsc-dataseed.binance.org/', 'https://bsc-dataseed3.binance.org'],
    symbol: 'BNB',
    decimals: 18,
    blockExplorerUrls: 'https://bscscan.com/',
    icon: BinanceIcon,
    routerUri: 'https://aggregator-api.kyberswap.com/bsc/route/encode',
    kyberSwapLiqAddress: '0xcC02203b036E93B601A1458eAd8bbBa968DA4Cc4',
    liquidusAutoLPFarmInAddress: '0xD6b8aA87b61ac037374F0085936Ad13268ef10d5',
    liquidusAutoLPFarmOutAddress: '0x3505aFC3a5e9D0650712f58A2D433547dc6c1825',
    shortName: 'bsc',
    explorerApiUrl: 'https://api.bscscan.com',
  },
};

export const RPC_URLS: {[chainId: number]: string[]} = Object.keys(NETWORKS).reduce<{[chainId: number]: string[]}>(
  (accumulator, chainId) => {
    const validURLs = NETWORKS[Number(chainId)]?.rpcUrl;

    if (validURLs?.length) {
      accumulator[Number(chainId)] = validURLs;
    }

    return accumulator;
  },
  {},
);

export const getChainInfo = (chainId?: number) => {
  if (chainId) {
    return SUPPORTED_NETWORKS[chainId] ?? undefined;
  }
};

export const LifiContract = '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE';
