import {LiFiWidget} from '@lifi/widget';
import {lifiIntegrator} from 'src/constants';
import {DEVICE_ENUM} from 'src/styles';
import {useLifiWidget} from 'src/utils/lifi';
import styled from 'styled-components';

export const LifiWidget = () => {
  const {config} = useLifiWidget();

  return (
    <Wrapper>
      <Container>
        <LiFiWidget config={config} integrator={lifiIntegrator} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 0 16px;
  }
`;

const Container = styled.div`
  border-radius: 24px;
  padding: 34px 40px;
  overflow: hidden;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 24px 20px;
  }
`;
