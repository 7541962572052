import React from 'react';

import {ColorsTypes} from 'src/styles';

export type ChatBotIconType = {
  size?: number;
  color?: ColorsTypes;
};

const ChatBotIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width='200' height='200' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_28677_15011)'>
      <rect width='20' height='20' rx='10' fill='white' />
      <g clipPath='url(#clip1_28677_15011)'>
        <rect width='20' height='20' transform='matrix(-5.98511e-08 -1 -1 3.1924e-08 20 20)' fill='#0D0935' />
        <g filter='url(#filter0_f_28677_15011)'>
          <path
            d='M28.8697 20.9085C37.6024 17.1963 39.7215 8.30733 33.6028 1.05453C27.4842 -6.19827 15.4447 -9.06845 6.71201 -5.3562L1.4127 -3.10348C-7.32002 0.608761 -9.43914 9.49769 -3.32047 16.7505C2.79819 24.0033 14.8376 26.8735 23.5703 23.1612L28.8697 20.9085Z'
            fill='url(#paint0_radial_28677_15011)'
          />
        </g>
        <g style={{mixBlendMode: 'color-dodge'}} filter='url(#filter1_f_28677_15011)'>
          <path
            d='M8.56731 25.5924C16.8144 27.7842 26.7273 23.4423 30.7083 15.8944C34.6893 8.34656 31.231 0.450982 22.9839 -1.74085L14.4238 -4.01586C6.17669 -6.2077 -3.73615 -1.86578 -7.71717 5.68208C-11.6982 13.2299 -8.23985 21.1255 0.00724706 23.3174L8.56731 25.5924Z'
            fill='url(#paint1_radial_28677_15011)'
          />
        </g>
        <g style={{mixBlendMode: 'color-dodge'}} filter='url(#filter2_f_28677_15011)'>
          <path
            d='M-0.509278 25.0193C6.12056 26.7813 14.0895 23.2909 17.2898 17.2231C20.4902 11.1554 17.71 4.80815 11.0802 3.04614L4.19876 1.21726C-2.43108 -0.544755 -10.4 2.94571 -13.6004 9.01343C-16.8007 15.0812 -14.0205 21.4284 -7.39071 23.1904L-0.509278 25.0193Z'
            fill='url(#paint2_radial_28677_15011)'
          />
        </g>
        <g style={{mixBlendMode: 'color-dodge'}} filter='url(#filter3_f_28677_15011)'>
          <path
            d='M-10.186 23.5163C-3.5562 25.2783 4.41275 21.7878 7.61309 15.7201C10.8134 9.65236 8.03327 3.3051 1.40343 1.54309L-5.478 -0.285793C-12.1078 -2.04781 -20.0768 1.44266 -23.2771 7.51038C-26.4775 13.5781 -23.6973 19.9254 -17.0675 21.6874L-10.186 23.5163Z'
            fill='url(#paint3_radial_28677_15011)'
          />
        </g>
        <path
          d='M14.0844 4.60413C14.0844 5.2735 14.8539 6.0615 15.5417 6.0615C14.8275 6.0615 14.0844 6.84134 14.0844 7.51887C14.0844 6.83693 13.2949 6.0615 12.627 6.0615C13.3214 6.0615 14.0844 5.2735 14.0844 4.60413Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.7853 7.44375V12.6081C11.7853 12.6878 11.6666 12.6973 11.6533 12.6186L10.0302 3L8.40643 12.6189C8.39319 12.6976 8.27446 12.6881 8.27446 12.6084V7.44375L6.62428 13.1961C6.55948 13.4219 6.62446 13.6643 6.79419 13.8301L9.56049 16.5323C9.81994 16.7857 10.2405 16.7857 10.4999 16.5323L13.2662 13.8301C13.436 13.6643 13.5009 13.4219 13.4361 13.1961L11.7853 7.44375Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_f_28677_15011'
        x='-30.8174'
        y='-30.9796'
        width='91.917'
        height='79.7642'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='12' result='effect1_foregroundBlur_28677_15011' />
      </filter>
      <filter
        id='filter1_f_28677_15011'
        x='-21.3701'
        y='-16.5841'
        width='65.7305'
        height='54.7448'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='6' result='effect1_foregroundBlur_28677_15011' />
      </filter>
      <filter
        id='filter2_f_28677_15011'
        x='-26.9287'
        y='-11.2396'
        width='57.5469'
        height='48.7157'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='6' result='effect1_foregroundBlur_28677_15011' />
      </filter>
      <filter
        id='filter3_f_28677_15011'
        x='-36.6055'
        y='-12.7427'
        width='57.5469'
        height='48.7157'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='6' result='effect1_foregroundBlur_28677_15011' />
      </filter>
      <radialGradient
        id='paint0_radial_28677_15011'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(12.7364 11.7112) rotate(-154.236) scale(20.6468 17.4187)'
      >
        <stop stopColor='#7A5AF8' />
        <stop offset='1' stopColor='#E888F8' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_28677_15011'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(22.3907 8.69771) rotate(160.319) scale(18.091 14.3058)'
      >
        <stop stopColor='#F257A0' />
        <stop offset='1' stopColor='#484EB7' stopOpacity='0' />
        <stop offset='1' stopColor='#D03F92' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint2_radial_28677_15011'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(-2.61742 16.8971) rotate(-144.329) scale(13.5195 12.8365)'
      >
        <stop stopColor='#112455' />
        <stop offset='1' stopColor='#17E7D6' />
      </radialGradient>
      <radialGradient
        id='paint3_radial_28677_15011'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(-12.2942 15.394) rotate(-144.329) scale(13.5195 12.8365)'
      >
        <stop stopColor='#112455' />
        <stop offset='1' stopColor='#17E7D6' />
      </radialGradient>
      <clipPath id='clip0_28677_15011'>
        <rect width='20' height='20' rx='10' fill='white' />
      </clipPath>
      <clipPath id='clip1_28677_15011'>
        <rect width='20' height='20' fill='white' transform='matrix(-5.98511e-08 -1 -1 3.1924e-08 20 20)' />
      </clipPath>
    </defs>
  </svg>
);

export default ChatBotIcon;
